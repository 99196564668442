import $ from 'jquery';

import '@accessible360/accessible-slick';

// jquery match height NMP
import jqueryMatchHeight from 'jquery-match-height';

// Custom Modules

import general from './modules/general';
import frontpage from './modules/frontpage';

import fancyBox from './modules/fancybox';

//import accordion from './modules/accordion';

//import flexGrid from './modules/flex-grid';

/* window.onload = () => {
	modules.init();
}; */

document.addEventListener('DOMContentLoaded', function () {
	general.init();
	frontpage.init();
	fancyBox.init();
	//accordion.init();
	// flexGrid.init();


	

	$('.slick').slick();
	// $('.slick-cloned').find('a').removeAttr('data-fancybox'); // remove duplicate fancbox

}, false);